<template>
  <div class="content">
    <van-nav-bar
      :title="staName"
      left-arrow
      fixed
      safe-area-inset-top
      @click-left="goBack"
    />
    <bus-list class="bus-list" :list='busList'></bus-list>
  </div>
</template>

<script lang="ts">
import api from '@/api'
import { AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import BusList from '../home/BusList.vue'

export default defineComponent({
  components: {
    BusList
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const { cityId, staName, busListStr } = route.query
    const state = reactive({
      busList: []
    })
    console.log(route.query)
    api.bus.queryMultiBuses({
      cityid: cityId,
      busListStr
    }).then((res: AxiosRequestConfig["data"]) => {
      if (res?.code === 0) {
        state.busList = res.data
      }
    })
    const goBack = () => {
      router.go(-1)
    }

    Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });

    return {
      goBack,
      staName,
      ...toRefs(state)
    }
  },
})
</script>


<style lang="less" scoped>
.bus-list {
  margin-top: 80px;
}
</style>
