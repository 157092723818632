<template>
  <div class="bus">
    <div class="bus-item" v-for="item in list" :key="item" @click="handleBusInfo(item)">
      <template v-if="item.isRecord !== 0">
        <i class="iconfont icon-bus"></i>
        <div class="bus">
          <p class="name">{{item.staName}} <span class="city">{{item.city}}</span> </p>
          <p class="from-to">
            {{ item.staStart }}
            →
            {{ item.staEnd }}
          </p>
        </div>
      </template>
      <template v-else>
        <i class="iconfont icon-bus"></i>
        <div class="bus">
          <p class="name">{{item.staName}}</p>
          <p class="from-to">
            线路未收录
          </p>
        </div>
      </template>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IObject } from '@/types/inerface'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'

export default defineComponent({
  props: {
    list: Array
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const handleBusInfo = (bus: IObject) => {
      if (bus.isRecord === 0) {
        return Toast.fail("线路未收录");
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      try {
        let busList = localStorage.busList === undefined ? [] : JSON.parse(localStorage.busList)
        let isHaveFlag = busList.some((item: IObject) => item.staName === bus.staName)
        console.log(isHaveFlag)
        if (!isHaveFlag) {
          busList.push({ ...bus, cityId: store.state.city.id, city: store.state.city.city })
          localStorage.busList = JSON.stringify(busList) 
        }
      } catch (error) {
        console.log(error)
      }
      router.push({
        path: '/busInfo',
        query: {
          ...bus,
          cityId: store.state.city.id
        }
      })
    }
    return { handleBusInfo }
  },
})
</script>


<style lang="less" scoped>
.bus {
  background: @base-background-color;
}
.bus-item {
  padding: 18px;
  display: flex;
  border-bottom: 2px solid #ddd;
  background-color: #fff;
  i{
    padding: 0 30px;
    font-size: 48px;
    color: #07c160;
    line-height: 90px;
  }
  p {
    margin: 0;
    color: #333;
    background-color: #fff;
  }
  .name {
    font-size: @base-font-size;
    .city {
      font-size: 16px;
      color: rgb(133, 133, 133);
    }
  }
  .from-to {
    padding-top: 12px;
    font-size: 26px;
    color: rgb(112, 112, 112);
  }
}

</style>
