
import { defineComponent } from 'vue'
import { IObject } from '@/types/inerface'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'

export default defineComponent({
  props: {
    list: Array
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const handleBusInfo = (bus: IObject) => {
      if (bus.isRecord === 0) {
        return Toast.fail("线路未收录");
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      try {
        let busList = localStorage.busList === undefined ? [] : JSON.parse(localStorage.busList)
        let isHaveFlag = busList.some((item: IObject) => item.staName === bus.staName)
        console.log(isHaveFlag)
        if (!isHaveFlag) {
          busList.push({ ...bus, cityId: store.state.city.id, city: store.state.city.city })
          localStorage.busList = JSON.stringify(busList) 
        }
      } catch (error) {
        console.log(error)
      }
      router.push({
        path: '/busInfo',
        query: {
          ...bus,
          cityId: store.state.city.id
        }
      })
    }
    return { handleBusInfo }
  },
})
